import { default as aboutujjxK5oGAYMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/about.vue?macro=true";
import { default as articlesi4aaExpfgXMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/articles.vue?macro=true";
import { default as calendarQrVM6Crz9gMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/calendar.vue?macro=true";
import { default as contactNUP0Nvlxp3Meta } from "/home/tjbp/cloud/clubscribe/client-next/pages/contact.vue?macro=true";
import { default as _91slug_93TwvSTgzyCkMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/editor/[slug].vue?macro=true";
import { default as indexVp1AKopmB8Meta } from "/home/tjbp/cloud/clubscribe/client-next/pages/index.vue?macro=true";
import { default as joinzRbwRLXtyJMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/join.vue?macro=true";
import { default as indexdtsLrBypKlMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/login/index.vue?macro=true";
import { default as resetJj4FfJ7WHbMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/login/reset.vue?macro=true";
import { default as airtimeWOLkDwWcrcMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/members/airtime.vue?macro=true";
import { default as detailsJR96aOxp1uMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/members/details.vue?macro=true";
import { default as _91name_93rQDgOHxNgnMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/members/groups/[name].vue?macro=true";
import { default as indexk2bE6vXp0AMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/members/groups/index.vue?macro=true";
import { default as indexLKqgMa1zOKMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/members/index.vue?macro=true";
import { default as _91id_93WIXuvkEYyxMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/members/messages/[id].vue?macro=true";
import { default as indexWNNaWfcvVyMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/members/messages/index.vue?macro=true";
import { default as threadsU2WyieBxVNMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/members/messages/threads.vue?macro=true";
import { default as notificationsEVr6sRlswSMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/members/notifications.vue?macro=true";
import { default as privacyZo4lGro4GJMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/members/privacy.vue?macro=true";
import { default as _91username_93XoTyPhthxLMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/members/roster/[username].vue?macro=true";
import { default as indexkGqOfd6D1cMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/members/roster/index.vue?macro=true";
import { default as subscriptionzkTAA7xVCtMeta } from "/home/tjbp/cloud/clubscribe/client-next/pages/members/subscription.vue?macro=true";
export default [
  {
    name: aboutujjxK5oGAYMeta?.name ?? "about",
    path: aboutujjxK5oGAYMeta?.path ?? "/about",
    meta: aboutujjxK5oGAYMeta || {},
    alias: aboutujjxK5oGAYMeta?.alias || [],
    redirect: aboutujjxK5oGAYMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/about.vue").then(m => m.default || m)
  },
  {
    name: articlesi4aaExpfgXMeta?.name ?? "articles",
    path: articlesi4aaExpfgXMeta?.path ?? "/articles",
    meta: articlesi4aaExpfgXMeta || {},
    alias: articlesi4aaExpfgXMeta?.alias || [],
    redirect: articlesi4aaExpfgXMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/articles.vue").then(m => m.default || m)
  },
  {
    name: calendarQrVM6Crz9gMeta?.name ?? "calendar",
    path: calendarQrVM6Crz9gMeta?.path ?? "/calendar",
    meta: calendarQrVM6Crz9gMeta || {},
    alias: calendarQrVM6Crz9gMeta?.alias || [],
    redirect: calendarQrVM6Crz9gMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/calendar.vue").then(m => m.default || m)
  },
  {
    name: contactNUP0Nvlxp3Meta?.name ?? "contact",
    path: contactNUP0Nvlxp3Meta?.path ?? "/contact",
    meta: contactNUP0Nvlxp3Meta || {},
    alias: contactNUP0Nvlxp3Meta?.alias || [],
    redirect: contactNUP0Nvlxp3Meta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93TwvSTgzyCkMeta?.name ?? "editor-slug",
    path: _91slug_93TwvSTgzyCkMeta?.path ?? "/editor/:slug()",
    meta: _91slug_93TwvSTgzyCkMeta || {},
    alias: _91slug_93TwvSTgzyCkMeta?.alias || [],
    redirect: _91slug_93TwvSTgzyCkMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/editor/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexVp1AKopmB8Meta?.name ?? "index",
    path: indexVp1AKopmB8Meta?.path ?? "/",
    meta: indexVp1AKopmB8Meta || {},
    alias: indexVp1AKopmB8Meta?.alias || [],
    redirect: indexVp1AKopmB8Meta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/index.vue").then(m => m.default || m)
  },
  {
    name: joinzRbwRLXtyJMeta?.name ?? "join",
    path: joinzRbwRLXtyJMeta?.path ?? "/join",
    meta: joinzRbwRLXtyJMeta || {},
    alias: joinzRbwRLXtyJMeta?.alias || [],
    redirect: joinzRbwRLXtyJMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/join.vue").then(m => m.default || m)
  },
  {
    name: indexdtsLrBypKlMeta?.name ?? "login",
    path: indexdtsLrBypKlMeta?.path ?? "/login",
    meta: indexdtsLrBypKlMeta || {},
    alias: indexdtsLrBypKlMeta?.alias || [],
    redirect: indexdtsLrBypKlMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: resetJj4FfJ7WHbMeta?.name ?? "login-reset",
    path: resetJj4FfJ7WHbMeta?.path ?? "/login/reset",
    meta: resetJj4FfJ7WHbMeta || {},
    alias: resetJj4FfJ7WHbMeta?.alias || [],
    redirect: resetJj4FfJ7WHbMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/login/reset.vue").then(m => m.default || m)
  },
  {
    name: airtimeWOLkDwWcrcMeta?.name ?? "members-airtime",
    path: airtimeWOLkDwWcrcMeta?.path ?? "/members/airtime",
    meta: airtimeWOLkDwWcrcMeta || {},
    alias: airtimeWOLkDwWcrcMeta?.alias || [],
    redirect: airtimeWOLkDwWcrcMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/members/airtime.vue").then(m => m.default || m)
  },
  {
    name: detailsJR96aOxp1uMeta?.name ?? "members-details",
    path: detailsJR96aOxp1uMeta?.path ?? "/members/details",
    meta: detailsJR96aOxp1uMeta || {},
    alias: detailsJR96aOxp1uMeta?.alias || [],
    redirect: detailsJR96aOxp1uMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/members/details.vue").then(m => m.default || m)
  },
  {
    name: _91name_93rQDgOHxNgnMeta?.name ?? "members-groups-name",
    path: _91name_93rQDgOHxNgnMeta?.path ?? "/members/groups/:name()",
    meta: _91name_93rQDgOHxNgnMeta || {},
    alias: _91name_93rQDgOHxNgnMeta?.alias || [],
    redirect: _91name_93rQDgOHxNgnMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/members/groups/[name].vue").then(m => m.default || m)
  },
  {
    name: indexk2bE6vXp0AMeta?.name ?? "members-groups",
    path: indexk2bE6vXp0AMeta?.path ?? "/members/groups",
    meta: indexk2bE6vXp0AMeta || {},
    alias: indexk2bE6vXp0AMeta?.alias || [],
    redirect: indexk2bE6vXp0AMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/members/groups/index.vue").then(m => m.default || m)
  },
  {
    name: indexLKqgMa1zOKMeta?.name ?? "members",
    path: indexLKqgMa1zOKMeta?.path ?? "/members",
    meta: indexLKqgMa1zOKMeta || {},
    alias: indexLKqgMa1zOKMeta?.alias || [],
    redirect: indexLKqgMa1zOKMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/members/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WIXuvkEYyxMeta?.name ?? "members-messages-id",
    path: _91id_93WIXuvkEYyxMeta?.path ?? "/members/messages/:id()",
    meta: _91id_93WIXuvkEYyxMeta || {},
    alias: _91id_93WIXuvkEYyxMeta?.alias || [],
    redirect: _91id_93WIXuvkEYyxMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/members/messages/[id].vue").then(m => m.default || m)
  },
  {
    name: indexWNNaWfcvVyMeta?.name ?? "members-messages",
    path: indexWNNaWfcvVyMeta?.path ?? "/members/messages",
    meta: indexWNNaWfcvVyMeta || {},
    alias: indexWNNaWfcvVyMeta?.alias || [],
    redirect: indexWNNaWfcvVyMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/members/messages/index.vue").then(m => m.default || m)
  },
  {
    name: threadsU2WyieBxVNMeta?.name ?? "members-messages-threads",
    path: threadsU2WyieBxVNMeta?.path ?? "/members/messages/threads",
    meta: threadsU2WyieBxVNMeta || {},
    alias: threadsU2WyieBxVNMeta?.alias || [],
    redirect: threadsU2WyieBxVNMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/members/messages/threads.vue").then(m => m.default || m)
  },
  {
    name: notificationsEVr6sRlswSMeta?.name ?? "members-notifications",
    path: notificationsEVr6sRlswSMeta?.path ?? "/members/notifications",
    meta: notificationsEVr6sRlswSMeta || {},
    alias: notificationsEVr6sRlswSMeta?.alias || [],
    redirect: notificationsEVr6sRlswSMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/members/notifications.vue").then(m => m.default || m)
  },
  {
    name: privacyZo4lGro4GJMeta?.name ?? "members-privacy",
    path: privacyZo4lGro4GJMeta?.path ?? "/members/privacy",
    meta: privacyZo4lGro4GJMeta || {},
    alias: privacyZo4lGro4GJMeta?.alias || [],
    redirect: privacyZo4lGro4GJMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/members/privacy.vue").then(m => m.default || m)
  },
  {
    name: _91username_93XoTyPhthxLMeta?.name ?? "members-roster-username",
    path: _91username_93XoTyPhthxLMeta?.path ?? "/members/roster/:username()",
    meta: _91username_93XoTyPhthxLMeta || {},
    alias: _91username_93XoTyPhthxLMeta?.alias || [],
    redirect: _91username_93XoTyPhthxLMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/members/roster/[username].vue").then(m => m.default || m)
  },
  {
    name: indexkGqOfd6D1cMeta?.name ?? "members-roster",
    path: indexkGqOfd6D1cMeta?.path ?? "/members/roster",
    meta: indexkGqOfd6D1cMeta || {},
    alias: indexkGqOfd6D1cMeta?.alias || [],
    redirect: indexkGqOfd6D1cMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/members/roster/index.vue").then(m => m.default || m)
  },
  {
    name: subscriptionzkTAA7xVCtMeta?.name ?? "members-subscription",
    path: subscriptionzkTAA7xVCtMeta?.path ?? "/members/subscription",
    meta: subscriptionzkTAA7xVCtMeta || {},
    alias: subscriptionzkTAA7xVCtMeta?.alias || [],
    redirect: subscriptionzkTAA7xVCtMeta?.redirect,
    component: () => import("/home/tjbp/cloud/clubscribe/client-next/pages/members/subscription.vue").then(m => m.default || m)
  }
]