import validate from "/home/tjbp/cloud/clubscribe/client-next/node_modules/nuxt/dist/pages/runtime/validate.js";
import _10_45load_45user_45global from "/home/tjbp/cloud/clubscribe/client-next/middleware/10-load-user.global.ts";
import _20_45auth_45global from "/home/tjbp/cloud/clubscribe/client-next/middleware/20-auth.global.ts";
import manifest_45route_45rule from "/home/tjbp/cloud/clubscribe/client-next/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _10_45load_45user_45global,
  _20_45auth_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}